import firebase from "./firebase"

document.getElementById('form').addEventListener('submit', function(e) {
    e.preventDefault();

    const email = document.getElementById('email')
    const password = document.getElementById('password');
    firebase.auth().signInWithEmailAndPassword(email.value, password.value)
        .then((userCredential) => {
            // Signed in
            const user = userCredential.user;
            firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then(function(idToken) {
                railsLogin(user,idToken);
            }).catch(function(error) {
                alert("rails login failed");
            });
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = "メールアドレスまたはパスワードが間違っています。";
            alert(errorMessage);
            location.reload();
        });
})

const csrfTokenObj = () => {
    return { "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr('content') };
}

const authorizationObj = (idToken) => {
    return { "Authorization": `Bearer ${idToken}` };
}

const railsLogin = (user,idToken) => {
    const headers = Object.assign(csrfTokenObj(), authorizationObj(idToken));
    $.ajax({
        type: "POST",
        url: "/",
        data: {
            refresh_token: user.refreshToken
        },
        headers: headers
    })
        .done(function (data){
        })
        .fail(function (XMLHttpRequest, textStatus, errorThrown){
            alert("AjaxError!");
        })
};
